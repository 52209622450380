import React from 'react';

const About = ({ name }) => {
	return (
		<section className="about" id="about">
			<h3>
				Hi! I'm {name} <i className="animated hover-rotate em em-raised_hand_with_fingers_splayed" />
			</h3>
			<div className="details">
				<div className="item text-muted">
					<p>
						I'm a software engineer, devops engineer, solutions architect and infrastructure analyst from Brazil <i className="animated hover-bounce em-svg em-heart f-12" />
						I work with web development, cloud computing, infrastructure and DevOps. Feel free to contact me. 
					</p>
				</div>
			</div>
		</section>
	);
};

export { About };
