const config = {
        name: 'Giovanne Oliveira',
        title: "Hey, I'm Giovanne 👋🏻",
        blog: '', // Dev.to or Medium (Make blank if you don't need the blog)

        // Social media profile username
        github: 'giovanne-oliveira', // [REQUIRED] Github Username
        twitter: 'giovannedev', // Twitter Username
        stackoverflow: '', // Eg: 3211944
        medium: '', // without @ symbol
        instagram: '',
        dev: '',
        youtube: '', // Channel ID
        linkedin: 'giovanne-oliveira'
};

export { config };
